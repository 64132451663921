<template>
  <div>
    <v-icon
      color="primary"
      dark
      @click="open = !open"
      class="ap-hover-icon"
    >
      info
    </v-icon>

    <ServerInfoDialog
      :open="open"
      @clicked="open = !open"
    />

  </div>
</template>
<script>
export default {
  name: 'TheToolbarInfoIcon',
  data: () => ({
    open: false,
  }),
  components: {
    ServerInfoDialog: () => import('@/components/dialogs/ServerInfoDialog'),
  },
};
</script>
